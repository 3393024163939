
import Hero from 'src/components/home/HomeHero';
import Head from 'next/head';
import CookiesNotification from 'src/components/CookiesNotification';



const HomeView = () => {
  

  return (
    <>
      <Head><title>BANKAYO | Software For Microfinance</title></Head>
      <Hero />
      <CookiesNotification/>
    </>
  );
};

export default HomeView;
