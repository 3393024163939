import React, {
  useState,
  
} from 'react';

import { Box, Button, Portal, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {useTheme} from "@mui/material/styles"
import { InstallDesktop, InstallMobile } from '@mui/icons-material';
import useUserAgent from 'src/hooks/useUserAgent';
import useAddToScreenPrompt from 'src/hooks/useAddToHomeScreenPrompt';




const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.common.black,
    //color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    //margin: theme.spacing(3),
    //padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000
  },
  action: {
    //backgroundColor: theme.palette.common.white,
    //color: theme.palette.common.black
  }
}));

const CookiesNotification = () => {
  // const classes = useStyles();

  const {isChrome,isMobile,isStandAlone} = useUserAgent()
  const [prompt,promptToInstall] = useAddToScreenPrompt()

  const theme = useTheme()

  
  
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    // Cookies.set('consent', 'true');
    setOpen(false);
  };

  /* useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []); */

  if (!open || !isChrome || isStandAlone) {
    return null;
  }

  return (
    
    <Portal>
      <div 
      style={{
        background: theme.palette.common.black, 
        color:theme.palette.common.white,
        position:"fixed",
        maxWidth:600,
        bottom:0,
        right:0,
        margin: theme.spacing(3),
        padding: theme.spacing(3),
        outline:"none",
        zIndex:2000}}>
        <Typography
          variant="body1"
          color="inherit"
        >
          This site can be installed as an application on your device. Click the install button to install.
          
        </Typography>
        <Box
          mt={2}
          display="flex"
          justifyContent="flex-end"
          
        >
          <Button
            sx={{mr:2}}
            onClick={handleClose}
            variant="outlined"
            
            
          >
            Not Now
          </Button>
          <Button
            onClick={promptToInstall}
            variant="contained"
            startIcon={isMobile? <InstallMobile/>:<InstallDesktop/>}
            
          >
            Install
          </Button>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
