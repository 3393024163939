import PropTypes from "prop-types";
import NextLink from "next/link";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  Toolbar,
} from "@mui/material";
import MenuIcon from "../../icons/Menu";
//import Logo from "../../components/Logo";
import LogoButton from "src/components/LogoButton";
import { Login } from "@mui/icons-material";

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        color: "text.secondary",
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: "none",
            },
          }}
          size="large">
          <MenuIcon fontSize="small" />
        </IconButton>
        

          {/* <Logo
            sx={{
              display: {
                md: "inline",
                xs: "none",
              },
              height: 40,
              width: 40,
            }}
          /> */}
          
            <LogoButton/>
          

        
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: "center",
            display: {
              sm: "flex",
              xs: "none",
            },
          }}
        >
          <NextLink href="/login" passHref legacyBehavior>
          <Button
            startIcon={<Login/>}
            color="primary"
            size="small"
            variant="contained"
          >
            Login
          </Button>
          </NextLink>

          
          
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2,
            }}
          />
          <Button
            color="primary"
            component="a"
            href="https://bankayo.io/contactus#bookademo"
            size="small"
            target="_blank"
            variant="contained"
          >
            Request For Demo
          </Button>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
