import { useEffect, useState } from "react";

export default function useUserAgent(){
    const [isMobile,setIsMobile] = useState(null)
    const [userAgent,setUserAgent] = useState(null)
    const [isIos,setIsIos] = useState(null)
    const [isChrome,setIsChrome] = useState(false)
    const [isStandAlone,setIsStandAlone] = useState(null)
    const [userAgentString,setUserAgentString] = useState("")

    useEffect(()=>{
        if(window){
            
            const userAgentString = window.navigator.userAgent
            setUserAgentString(userAgentString)
        }else{
            console.log("We did not access window")
        }
        let userAgent;
        if(userAgentString && userAgentString.indexOf('SamsungBrowser')>-1){
            userAgent = 'SamsungBrowser'
        }else if(userAgentString && userAgentString.indexOf('Firefox')>-1){
            userAgent = 'Firefox'
        }else if(userAgentString && userAgentString.indexOf('FxiOS')>-1){
            userAgent = 'FirefoxiOS'
        }else if(userAgentString && userAgentString.indexOf('CriOS')>-1){
            userAgent = 'ChromeiOS'
        }else if(userAgentString && userAgentString.indexOf('Chrome')>-1){
            userAgent = 'Chrome'
            setIsChrome(true)
        }else if(userAgentString && userAgentString.indexOf('Safari')>-1){
            userAgent = 'Safari'
        }else{
            userAgent = 'unknown'
        }
        setUserAgent(userAgent)

        //check if userAgent is mobile
        const isIOS = userAgentString.match(/iPhone|iPad|iPod/i)
        const isAndroid = userAgentString.match(/Android/i)
        setIsIos(isIOS?true:false)
        const isMobile = isIOS || isAndroid
        setIsMobile(!!isMobile)
        //check if app is installed, if it is installed we wont show the prompt
        if(window.matchMedia('(display-mode: standalone)').matches){
            setIsStandAlone(true)

        }
        if(window.matchMedia('(display-mode: minimal-ui)').matches){
          setIsStandAlone(true)

      }
    },[userAgentString])

    return {isIos,isMobile,isStandAlone,userAgentString,userAgent,isChrome}
}
