import { useEffect } from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { Box, Button, Chip, Drawer, Link } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../../components/Logo";
import LogoButton from "src/components/LogoButton";
import { Login } from "@mui/icons-material";

const MainSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useRouter();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname,onMobileClose,openMobile]);

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          width: 270,
        },
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 2,
          
        }}
      >
        

          <LogoButton/>

        
        {/* <Box
          sx={{
            display: "flex",
            pb: 2,
            pt: 3,
          }}
        >
          <NextLink href="/browse" passHref legacyBehavior>
            <Link color="textSecondary" underline="none" variant="body1">
              Browse Components
            </Link>
          </NextLink>

          <Chip
            color="primary"
            label="NEW"
            size="small"
            sx={{
              maxHeight: 20,
              ml: 1,
              mr: 2,
            }}
          />
        </Box>*/}
        <NextLink href="/login" passHref legacyBehavior>
          <Button startIcon={<Login/>} fullWidth variant="contained" sx={{mt:4}}>Login</Button>
        </NextLink>
 
        <Button
          fullWidth
          color="primary"
          component="a"
          href="https://bankayo.io/contactus#bookademo"
          variant="contained"
          target="_blank"
          sx={{mt:4}}
        >
          Book A Demo
        </Button>
      </Box>
    </Drawer>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default MainSidebar;
