import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useSettings from "src/hooks/useSettings";
import Image from "next/image";
import  Card  from "@mui/material/Card";


const HomeHero = (props) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState("");

  const {settings} = useSettings()


  useEffect(() => {
    (async () => {
      const response = await fetch(
        `/static/home/hero_${theme.palette.mode}.png`
      );
      const blob = await response.blob();

      setImage(URL.createObjectURL(blob));
      setIsLoading(false);
    })();
  }, [theme.palette.mode]);

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        pt: 6,
        
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          px: {
            md: "50px !important",
          },
        }}
      >
        {/* <Typography color="primary" variant="overline">
          Welcome to
        </Typography> */}
        <Typography align="center" color="textPrimary" variant="h3">
        {settings.fullname ? settings.fullname:"BANKAYO"}
        </Typography>
        <Typography
          align="center"
          fontWeight={700}
          color="textSecondary"
          variant="h1"
          sx={{ py: 3 }}
        >
          BANKAYO Microfinance Software
        </Typography>
        
        
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          borderRadius:4,
          px: {
            md: 15,
          },
        }}
        
      >
       <Card> 
        <Box
          display={"flex"}
          alignContent={"center"}
          justifyContent={"center"}
          sx={{
            position: "relative",
            pt: !isLoading && "52.13%",
            
          }}
        >
          
          
          {isLoading ? (
            <Skeleton
              sx={{
                borderRadius: 1,
                width: "100%",
                pt: "52.13%",
              }}
              variant="rectangular"
            />
          ) : (
            <Image
              alt="Hero"
              width={1321}
              height={812}
              src={image}
              style={{
                maxWidth: "100%",
                width:"auto",
                height:"auto",
                zIndex: 20,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
              }}
            />
          )}
          
        </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default HomeHero;
